import apiClient from '@/utils/api-client'

import {Payment} from "@/model/models";

class PaymentService {
    public getPaymentList() {
        return new Promise<boolean>((resolve, reject) => {
            apiClient.get('/api/v1/payments').then((res) => {
                const list: Payment[] = [];
                const paymentList = res.data.paymentList
                for (const x in paymentList) {
                    list.push(new Payment(paymentList[x].paymentId, paymentList[x].plan, paymentList[x].planEndDate,
                        paymentList[x].amount, paymentList[x].paymentSystem, paymentList[x].paymentDate));
                }

                localStorage.setItem('paymentList', JSON.stringify(list))
                resolve(true)
            }).catch((error) => {
                reject(error)
            })
        })
    }
}

export default new PaymentService()
